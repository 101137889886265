import { Alerts, Toast, Typography } from '@loophealth/loop-ui-web-library';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { updateEndoBackdate } from '../../../adapters';
import { AnnounceIcon, CloudRefreshIcon } from '../../../assets/img';
import { ReduxState } from '../../../redux';
import { DEFAULT_DATE_FORMAT } from '../../../utils/dateFormatService';
import {
    StyledBackdatedTextContainer,
    StyledRefreshBackdatedMembersCard
} from './styles';
import { IRefreshBackdatedMembersCard } from './types';

const RefreshBackdatedMembersCard: React.FC<IRefreshBackdatedMembersCard> = ({
    backdateApplicableMembers,
    endorsementId,
    policyId,
    fetchEndorsementDetails
}) => {
    const toast = Toast.useToast();
    const elementRef = useRef<HTMLDivElement>(null);
    const defaultOffsetTop = useRef<number | null>(null);
    const [isElementSticked, setIsElementSticked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const currentUser = useSelector(
        (state: ReduxState) => state.user?.userData?.data
    );
    const currentUserId = currentUser?.userId || '';
    const totalCount = backdateApplicableMembers.length;

    useEffect(() => {
        const appWrapper = document.getElementById('endo-details-wrapper');
        const handleScroll = () => {
            if (elementRef.current) {
                const offsetTop = elementRef.current.offsetTop;
                if (defaultOffsetTop.current === null) {
                    defaultOffsetTop.current = elementRef.current.offsetTop;
                }
                setIsElementSticked(offsetTop !== defaultOffsetTop.current);
            }
        };

        appWrapper?.addEventListener('scroll', handleScroll);
        return () => appWrapper?.removeEventListener('scroll', handleScroll);
    }, []);

    const handleRefreshMembers = async () => {
        setIsLoading(true);
        try {
            const memberUserIds = backdateApplicableMembers.map(
                (member) => member.userId
            );
            const todayDate = moment();
            const newCoverageStartDate = todayDate
                .subtract(40, 'days')
                .format(DEFAULT_DATE_FORMAT);
            await updateEndoBackdate(
                endorsementId,
                memberUserIds,
                policyId,
                newCoverageStartDate,
                currentUserId
            );
            fetchEndorsementDetails(true);
            toast?.success('Member data successfully refreshed', '', {
                variant: 'dark'
            });
        } catch (e) {
            toast?.error((e as Error).message);
        } finally {
            setIsLoading(false);
        }
    };

    return totalCount ? (
        <StyledRefreshBackdatedMembersCard
            ref={elementRef}
            $isSticked={isElementSticked}
        >
            <Alerts.StatusAlert
                iconSrc={AnnounceIcon}
                text={
                    <StyledBackdatedTextContainer>
                        <Typography variant="medium" weight="semiBold" color="planRed">
              Attention: Coverage Dates Outdated!
                        </Typography>
                        <Typography variant="medium">
                            {totalCount} lives have coverage start dates that are more than 45
              days old. Click “Refresh Member Data” to automatically back date
              by 40 days from today.
                        </Typography>
                    </StyledBackdatedTextContainer>
                }
                variant={isElementSticked ? 'inline' : 'over'}
                buttonText="Refresh Member Data"
                onButtonClick={handleRefreshMembers}
                buttonProps={{
                    variant: 'filled',
                    iconOrder: 'left',
                    iconSrc: CloudRefreshIcon,
                    isLoading
                }}
            />
        </StyledRefreshBackdatedMembersCard>
    ) : null;
};

export default RefreshBackdatedMembersCard;
