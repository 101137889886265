import {
    Dialog,
    FullScreenLoader,
    LoopButton,
    Modal,
    PaginatedTable,
    Typography
} from '@loophealth/loop-ui-web-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { identifyKyc, identifyMidterms } from '../../../../../adapters';
import {
    BackdateCalendarImg,
    LogoutIcon,
    LoopEmeraldDownloadIcon
} from '../../../../../assets/img';
import { ReduxState } from '../../../../../redux';
import {
    resetState,
    setIdentifiedKyc,
    setIdentifiedMidterms,
    setRejectedEntries
} from '../../../../../redux/slices/ValidatorSlice';
import { showApiFailureToast } from '../../../../../utils/common';
import {
    EXTERNAL_ROUTES,
    INTERNAL_ROUTES
} from '../../../../../utils/constants/Routes';
import { useToast } from '../../../../hooks/useToast';
import { getUpdatedRejectedEntries } from '../DnfChecklist/utils';
import {
    generateIdentifyKycRequest,
    generateIdentifyMidTermRequest
} from '../Validator/utils';
import {
    StyledBackdateBody,
    StyledBackdateBodyHeader,
    StyledBackdateBodyWrapper,
    StyledBackdateChecklistContainer,
    StyledBackdateHeader,
    StyledBackdateHeaderWrapper
} from './styles';
import CustomBottomNavigator from '../../../../containers/CustomBottomNavigator';
import { BackdatedMemberTableColumns } from './constants';
import {
    IIdentifiedKycData,
    IIdentifiedMidtermData
} from '../../../../../redux/slices/ValidatorSlice/types';
import generateBackdatedDataSheet from './utils';

const BackdateChecklist: React.FC = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        identifiedBackdates,
        identifiedKyc,
        identifiedMidTerms,
        membersToUpload: uploadedData,
        policyId: selectedPolicyId,
        companyId: selectedCompanyId,
        rejectedEntries
    } = useSelector((state: ReduxState) => state.validatorSlice);
    const [isLoading, setIsLoading] = React.useState(false);
    const [cancelModalVisible, setCancelModalVisible] = React.useState(false);
    const backdatedLives = identifiedBackdates?.length ?? 0;
    const totalLives = uploadedData?.add.length || 0;
    const backdatedTableData = (identifiedBackdates?.map((member, index) => ({
        ...member,
        sno: `${index + 1}`
    })) ?? []) as Record<string, unknown>[];

    useEffect(() => {
        if (!identifiedBackdates || !uploadedData) {
            navigateToValidator();
        } else if (!identifiedBackdates.length) {
            validateData();
        }
    }, []);

    const validateData = async () => {
        const addEditUsers = generateIdentifyMidTermRequest(uploadedData);
        const userIds = generateIdentifyKycRequest(uploadedData);

        try {
            setIsLoading(true);
            const kycPromise = identifyKyc(userIds, selectedPolicyId ?? '');
            const midtermPromise = identifyMidterms(
                addEditUsers,
                selectedPolicyId ?? '',
                selectedCompanyId ?? ''
            );
            const [kycResponse, midtermResponse] = await Promise.all([
                kycPromise,
                midtermPromise
            ]);
            if (midtermResponse.data) {
                const updatedRejectedEntries = getUpdatedRejectedEntries(
                    midtermResponse.data.rejectedMidTermAdditions,
                    uploadedData,
                    rejectedEntries
                );
                dispatch(setRejectedEntries(updatedRejectedEntries));
            }
            dispatch(setIdentifiedKyc(kycResponse.data));
            dispatch(setIdentifiedMidterms(midtermResponse.data));
            navigateNext(kycResponse.data, midtermResponse.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showApiFailureToast(toast, (error as Error).toString());
        }
    };

    const handleNext = () => {
        if (!identifiedKyc || !identifiedMidTerms) {
            validateData();
        } else {
            navigateNext(identifiedKyc, identifiedMidTerms);
        }
    };

    const navigateNext = (
        identifiedKyc: IIdentifiedKycData,
        identifiedMidterms: IIdentifiedMidtermData
    ) => {
        if (identifiedKyc?.EDIT.length || identifiedKyc?.OFFBOARD.length) {
            navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.KYC_CHECKLIST.route);
        } else if (
            identifiedMidterms?.acceptedMidTermAdditions?.add?.length ||
            identifiedMidterms?.acceptedMidTermAdditions?.edit?.length
        ) {
            navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.DNF_CHECKLIST.route);
        } else {
            navigate(
                EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.VALIDATION_SUMMARY.route
            );
        }
    };

    const navigateToValidator = () => {
        dispatch(resetState());
        navigate(EXTERNAL_ROUTES.APP + '/' + INTERNAL_ROUTES.VALIDATOR.route);
    };

    const handleOnDownloadClick = () => {
        if (! identifiedBackdates) return;
        generateBackdatedDataSheet(identifiedBackdates, 'Backdated_Add_Members.xlsx');
    };

    if (isLoading) {
        return (
            <FullScreenLoader
                title="Before validating the Data..."
                subTitle="We’re doing some pre-checks for edit requests and mid term additions"
            />
        );
    }

    return (
        <StyledBackdateChecklistContainer>
            <StyledBackdateHeaderWrapper>
                <img src={BackdateCalendarImg} />
                <StyledBackdateHeader>
                    <Typography variant="title3" weight="semiBold">
                        {backdatedLives} out of {totalLives} lives will be back dated
                    </Typography>
                    <Typography variant="medium">
                        {backdatedLives} lives have coverage dates more than 45 days old.
                        They will be backdated to be 40 days from today.
                    </Typography>
                </StyledBackdateHeader>
            </StyledBackdateHeaderWrapper>
            <StyledBackdateBodyWrapper>
                <StyledBackdateBody>
                    <StyledBackdateBodyHeader>
                        <Typography variant="medium" weight="medium">
                            Backdated Member List
                        </Typography>
                        <div>
                            <LoopButton
                                onClick={handleOnDownloadClick}
                                variant="secondary"
                                iconSrc={LoopEmeraldDownloadIcon}
                                size="small"
                            >
                                Download Member Data
                            </LoopButton>
                        </div>
                    </StyledBackdateBodyHeader>
                    <PaginatedTable
                        data={backdatedTableData}
                        columns={BackdatedMemberTableColumns}
                        count={identifiedBackdates?.length ?? 0}
                        rowsPerPage={10}
                        position='bottom'
                        hideIfLess
                        fullWidth
                    />
                </StyledBackdateBody>
            </StyledBackdateBodyWrapper>
            <CustomBottomNavigator
                leftButtonClick={() => setCancelModalVisible(true)}
                rightButtonClick={handleNext}
                activateNext={true}
                rightButtonLabel="Save & Proceed"
                leftButtonLabel="Cancel"
            />
            <Modal
                isVisible={cancelModalVisible}
                setIsVisible={setCancelModalVisible}
            >
                <Dialog
                    variant="vertical"
                    icon={LogoutIcon}
                    description="Are you sure you want to leave? Exiting will cause all progress to be lost."
                    title="Heads Up: You’ll Lose Your Progress"
                    primaryButtonText="Yes, Exit"
                    onPrimaryButtonClicked={navigateToValidator}
                    onSecondaryButtonClicked={() => setCancelModalVisible(false)}
                    secondaryButtonText="Cancel"
                    buttonVariant="error"
                />
            </Modal>
        </StyledBackdateChecklistContainer>
    );
};

export default BackdateChecklist;
