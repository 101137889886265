import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { addPrevalidationToPolicy } from '../../../../../adapters';
import { Title } from '../../../../atoms';
import { useToast } from '../../../../hooks/useToast';
import { StyledContainer, StyledDescription } from './styles';

const PreValidation: React.FunctionComponent = () => {
    const toast = useToast();
    const [isPersistanceLoading, setIsPersistanceLoading] = React.useState(false);

    const onPreValidation = async () => {
        setIsPersistanceLoading(true);
        try {
            const result = await addPrevalidationToPolicy();
            if (result.data.policyNotfound.length > 0) {
                const policyNotfound = result.data.policyNotfound;
                toast?.displayToast(
                    'error',
                    'Policy not found',
                    policyNotfound.join(',')
                );
            } else if (result.data.policyPrevalidationErrorList.length > 0) {
                const policyPrevalidationErrorList =
                    result.data.policyPrevalidationErrorList;
                toast?.displayToast(
                    'error',
                    'Policy preValidation error',
                    policyPrevalidationErrorList.join(',')
                );
            } else if (
                result.data.policyPrevalidationErrorList.length == 0 &&
                result.data.policyNotfound.length == 0 &&
                result.data.preValidationAdded.length == 0
            ) {
                toast?.displayToast('error', 'Please check excel of prevalidation', '');
            } else {
                toast?.displayToast(
                    'success',
                    'Policy preValidation added successfully',
                    ''
                );
            }
        } catch (e) {
            toast?.displayToast('error', (e as Error).message, '');
        }
        setIsPersistanceLoading(false);
    };
    const url = process.env.REACT_APP_GS_PREVALIDATION_SHEET;

    return (
        <StyledContainer>
            <Title>Policy Prevalidation </Title>
            <div>
                <LoopButton
                    variant="filled"
                    onClick={onPreValidation}
                    isLoading={isPersistanceLoading}
                >
                    Add PreValidation for Policies
                </LoopButton>
            </div>
            <StyledDescription>
                &#40;
                <Typography variant="medium" weight="bold">
                    NOTE:&nbsp;
                    <Typography variant="medium">
                    Above button will invoke Prevalidation script which will read data from&nbsp;
                        <a href={url} target="_blank" rel="noreferrer">
                            excel
                        </a>&nbsp; and store it in firebase database
                    </Typography>
                </Typography>
                &#41;
            </StyledDescription>
        </StyledContainer>
    );
};

export default PreValidation;
